import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";

export default function OutsideCounty() {
  const [data, setData] = useState([]);
 

  useEffect(() => {
    fetch(
      `${erpApiUrl}&status[]=pending`
    )
      .then((response) => response.json())
      .then((result) => setData(result.response));
  }, []);

  const filteredData = data.reduce((accumulator, item) => {
    if (item.country_status === "Out Side Country") {
      accumulator.push(item);
    }
    return accumulator;
  }, []);
  
  
  return (
    <>


      <div style={{ marginTop: '20px'}}>
      <Allmaids data={filteredData} /> 
      </div>
    </>
  );
}
