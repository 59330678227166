import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";


export default function Amjad() {
  const [data, setData] = useState([]);
  const [maids] = useState("");

  useEffect(() => {
    fetch(
      `${erpApiUrl}&status[]=approved`
    )
      .then((response) => response.json())
      .then((result) => setData(result.response));
  }, []);

  const filteredData = data.filter((a) => a.maid_type === "" && a.name.toLowerCase().includes(maids.toLowerCase()));

  return (
    <>
 
      <div style={{ marginTop: '20px'}}>
      <Allmaids data={filteredData} /> 
      </div>

    </>
   
  );
}
