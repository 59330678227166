import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Modal, Backdrop, Fade } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from 'react-player';

const VideoMaid = ({ video }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closeTheVideo = () => {
    setIsOpen(false);
  };

  return (
    <>
 <Button style={{ color: 'black' , fontWeight: 'bold' }} className="gtm-header-get-visa btn-orange bg-gradient-to-r from-[#FFFB73] to-[#FFFB73] hover:from-yellow-300 hover:to-yellow-300 px-1.5 py-1.5 rounded-md shadow-lg cursor-pointer transform transition duration-300 hover:scale-105" onClick={openPopup}>
  Video
</Button>



      <Modal
        open={isOpen}
        onClose={closeTheVideo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '114%', sm: '114%', md: '70%', lg: '60%' },
              height: { xs: 'valueX', sm: 'valueL', md: 'valueM', lg: 'valueL' }, 
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto',
            }}
      
          >
                <Button
                variant="contained"
                color="primary"
                onClick={closeTheVideo}
                sx={{
                  width: "40px",
                  minWidth: "40px",
                  height: "40px",
                  borderRadius: "100%",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "1px 1px 5px 0px #bbbb",
                  background: "#ff0000",
                  mb: "20px",
                  mx: "auto",
                }}
              >
                <CloseIcon />
              </Button>
            <ReactPlayer
              url={video}
              playing={isOpen}
              width="100%"
              height="100%"
              controls
             
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default VideoMaid;
