import React, { useState } from 'react';

import buttonStyle from '../global/btn';

const BigphotoMaidsCV = ({ photo, maidName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative" onMouseLeave={() => setIsOpen(false)}>
    <button
  
  onClick={openPopup}
>
  <span className={buttonStyle}>
    Picture
  </span>
</button>


      {isOpen && (
        <div className="bg-white p-8 rounded-lg shadow-md w-96 max-w-80vw fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in">
          <button
            className="absolute top-2 right-2 text-xl text-gray-600 bg-transparent border-none cursor-pointer"
            onClick={closePopup}
          >
            X
          </button>
          <img
            src={photo}
            alt={maidName}
          />
        </div>
      )}
    </div>
  );
};


export default BigphotoMaidsCV;
