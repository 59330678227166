import React from 'react';

import styled from '@emotion/styled';

const FadeInContainer = styled.div`
    text-align: center;
    padding: 1rem;
    color: #3498db;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .text-container {
        display: inline-block;
        overflow: hidden;
    }

    .text-blue-800,
    .text-yellow-500 {
        display: inline-block;
    }

    .text-blue-800 {
        animation: slideInFromRight 2s ease-in-out forwards;
    }

    .text-yellow-500 {
        animation: slideInFromLeft 2s ease-in-out forwards;
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .bounce-text {
        display: inline-block;
        animation: bounce 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.2s forwards;
    }
    
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-15px);
        }
        60% {
            transform: translateY(-10px);
        }
    }
`;

export default function LogoHomecare() {
    return (
        <FadeInContainer>
            <h1 className="text-6xl font-extrabold tracking-tight mb-4 rounded-lg p-4 relative z-10">
                <span className="text-container">
                    <span className="text-blue-800">Home</span>
                </span>{" "}
                <span className="text-container">
                    <span className="text-yellow-500">Care</span>
                </span>
            </h1>

            <svg
                className="home-icon absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0"
                width="100px"
                height="100px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "-8rem" }}
            >
                <rect x="2" y="10" width="20" height="10" fill="#BDCDD6" />
                <polygon points="2,10 12,2 22,10" fill="transparent" stroke="#BDCDD6" strokeWidth="2" strokeLinejoin="round" />
            </svg>

            <p className="text-lg font-medium text-gray-500">
                Your Trusted <span className="bounce-text font-extrabold">TADBEER</span> Homecare
            </p>
        </FadeInContainer>
    );
}









