import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amjad from './hooks/categories/Amjad';
import OutsideCounty from './hooks/categories/Outsidecounty';
import Package4Maids from './hooks/categories/Package4Maids';
import NavBar from './navbar/NavBar';
import LogoHomecare from './navbar/Logo';


function App() {
  return (
    <Router>

      
       
       <NavBar/>
      
      
       <LogoHomecare/>

        <div>
          <Routes>
            <Route path="/" element={<Amjad />} />
            <Route path="/outside" element={<OutsideCounty />} />
            <Route path="/package4" element={<Package4Maids />} />
          </Routes>
        </div>
      
    </Router>
  );
}

export default App;
