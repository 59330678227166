import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PopupButton = ({ maidName, nationality }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bookingData, setBookingData] = useState({
    maidName: maidName,
    nationality: nationality,
    additionalInfo: '',
    phoneNumber: '',
    meetingRoom: '',
    assistantOptionNames: '',
  });
  const [notification, setNotification] = useState('');

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://homecare.maidsfamilycare.me/public/api/api/booking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookMaidCv: bookingData.maidName,
          customerBooking: bookingData.additionalInfo,
          phoneNumberBooking: bookingData.phoneNumber,
          nationalityBooking: bookingData.nationality,
          meetingRoom: bookingData.meetingRoom,
          assistantOptionNames: bookingData.assistantOptionNames,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setNotification('Data submitted successfully!');
        setTimeout(() => {
          setNotification('');
        }, 8000);
        closePopup();
      } else {
        throw new Error('An error occurred.');
      }
    } catch (error) {
      console.error(error);
      setNotification('An error occurred. Please try again.');
    }
  };

  return (
    
    <div className="relative" onMouseLeave={() => setIsOpen(false)}>

      <div className="space-y-1 flex">
      <button
        // className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-3 text-white text-lg"
         className='text-gray-400 text-lg font-semibold mb-0.02'
        onClick={openPopup}
      > 
        <p>{maidName}</p>
      </button>
      </div>
  
      {isOpen && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-10 rounded-lg shadow-md w-96 max-w-80vw z-10">
          <button
            className="absolute top-2 right-2 text-xl text-gray-600 bg-transparent border-none cursor-pointer"
            onClick={closePopup}
          >
            X
          </button>

          <h2 className="mb-4 text-xl font-bold">Book: {maidName}</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="additionalInfo"
              value={bookingData.additionalInfo}
              onChange={handleInputChange}
              placeholder="Customer name"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="phoneNumber"
              value={bookingData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="meetingRoom"
              value={bookingData.meetingRoom}
              onChange={handleInputChange}
              placeholder="Meeting room"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="assistantOptionNames"
              value={bookingData.assistantOptionNames}
              onChange={handleInputChange}
              placeholder="Assistant"
              className="w-full p-2 mb-2 border border-gray-300 rounded"
            />
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white border-none px-4 py-2 rounded cursor-pointer transition duration-300 mr-2"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={closePopup}
              className="bg-red-500 hover:bg-red-600 text-white border-none px-4 py-2 rounded cursor-pointer transition duration-300"
            >
              Close
            </button>
          </form>
        </div>
      )}

      {notification && (
        <div className="bg-red-600 text-white p-2 rounded mt-2">{notification}</div>
      )}
    </div>
  );
};

PopupButton.propTypes = {
  maidName: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
};

export default PopupButton;
