import React from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
  return (
    <nav className="bg-gradient-to-r from-[#8eddd9] via-[#8eddd9] to-[#8eddd9] py-2 px-4 md:py-3 md:px-6 rounded-full shadow-lg" style={{ marginTop: '25px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'}}>
      <ul className="flex justify-center space-x-4">
        <li>
          <Link
            to="/"
            className="text-black font-semibold text-lg hover:text-white transition duration-300 rounded-full p-2"
            style={{ whiteSpace: 'nowrap' }}
          >
            <span>Sponsor Visa</span>
          </Link>
        </li>
        <li>
          <Link
            to="/outside"
            className="text-black font-semibold text-lg hover:text-white  transition duration-300 rounded-full p-2"
            style={{ whiteSpace: 'nowrap' }}
          >
            <span>Out UAE</span>
          </Link>
        </li>
        <li>
          <Link
            to="/package4"
            className="text-black font-semibold text-lg hover:text-white transition duration-300 rounded-full p-2"
            style={{ whiteSpace: 'nowrap' }}
          >
            <span>Full Time</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
