import React, { useState } from 'react';
import PropTypes from 'prop-types';
import buttonStyle from '../global/btn';
const MaidsCV = ({
  maidName,
  nationality,
  workexp,
  religion,
  cooking,
  washing,
  cleaning,
  ironing,
  salary,
  phone
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative" onMouseLeave={() => setIsOpen(false)}>

  <span onClick={openPopup} className={buttonStyle}> CV Info </span>

      {isOpen && (
        <div className="bg-white p-8 rounded-lg shadow-md w-96 max-w-80vw fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in">
          <button
            className="absolute top-2 right-2 text-xl text-gray-600 bg-transparent border-none cursor-pointer"
            onClick={closePopup}
          >
            X
          </button>
          <h2 className="mb-4 text-orange-600 text-xl">{maidName}</h2>
          <h3 className="mb-2 text-gray-600 text-lg font-semibold">Basic Info:</h3>
          <table className="w-full border-collapse mt-4 border-2 border-gray-300">
            <tbody>
              <tr className="border-b border-gray-300 border-t">
                <td className="p-2 border-r border-gray-300">Nationality:</td>
                <td className="p-2">{nationality}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Experience:</td>
                <td className="p-2">{workexp.join(', ')}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Religion:</td>
                <td className="p-2">{religion}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Salary:</td>
                <td className="p-2">{salary}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Contact-number:</td>
                <td className="p-2">{phone}</td>
              </tr>
            </tbody>
          </table>

          <h3 className="mb-2 text-gray-600 text-lg font-semibold">Skills:</h3>

          <table className="w-full border-collapse mt-4 border-2 border-gray-300">
            <tbody>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Cooking:</td>
                <td className="p-2">{cooking}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Washing:</td>
                <td className="p-2">{washing}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Cleaning:</td>
                <td className="p-2">{cleaning}</td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300">Ironing:</td>
                <td className="p-2">Yes</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

MaidsCV.propTypes = {
  maidName: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  workexp: PropTypes.arrayOf(PropTypes.string).isRequired,
  religion: PropTypes.string.isRequired,
  cooking: PropTypes.bool.isRequired,
  washing: PropTypes.bool.isRequired,
  cleaning: PropTypes.bool.isRequired,
  ironing: PropTypes.bool.isRequired,
};

export default MaidsCV;
