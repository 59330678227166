import React from 'react';

const WhatsAppButton = ({ maid }) => {
  const phoneNumber = '+97143439977'; 

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(`Selected maid name: ${maid.name} from: ${maid.nationality}`)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="text-center" onClick={handleClick}>
      
        <img
         
          src='/whatsapplogo.jpg'
          alt='Whatsup Us'
          style={{ width: '60px', height: '60px' }}
        />
      
    </div>
  );
};

export default WhatsAppButton;
